import store from "../store/index.js";
import axios from "axios";
import router from "../router/index.js";

import {
  // MessageBox,
  Message,
  Loading,
} from "element-ui";
// 线上地址

// const host = 'https://api.duika8.cn';
// const host = 'http://new.market.card.xyk51.cn';

// 动态部署地址
function getBaseUrl() {
  const url = location.href.toString();
  let baseUrl = url.split("#")[0];
  baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  if (baseUrl.indexOf("17bbw.online") > 0) {
    return "https://apin.17bbw.online";
  } else {
    return "http://api.fengwocn.work.w6soft.com";
  }
}
const host = getBaseUrl();
const imgHost = ""; //静态图片路径

// 允许跨域携带cookie信息
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  let token = getToken();
  if (token) {
    config.headers["app-token"] = token;
  }
  return config;
});

/*获取token*/
function getToken() {
  let token;
  if (store.state.token) {
    token = store.state.token;
  } else {
    token = null;
  }
  return token;
}

export function request(url, method, data, header, isFalse) {
  let loadingInstance;
  return new Promise((resolve, reject) => {
    if (isFalse) {
      // 加载中图标
      loadingInstance = Loading.service();
    }

    var reqdata = data || {};
    let obj = {
      url: host + url,
      method: method,
    };
    if (method == "get") {
      obj.params = reqdata;
    }
    if (method == "post") {
      obj.data = reqdata;
    }

    if (header) {
      obj.headers = header;
    }

    axios(obj)
      .then((res) => {
        if (loadingInstance) {
          loadingInstance.close();
        }

        if (
          res.data.code == 1 ||
          res.data.status == "200" ||
          typeof res.data == "string"
        ) {
          resolve(res);
        } else {
          if (res.data.code == 0 && res.data.msg) {
            Message(res.data.msg);
            reject(res);
            return;
          }
          if (res.data.code == -1) {
            store.commit("logout");
            router
              .replace({ path: "/web/login", query: { login: 1 } })
              .catch((err) => {
                console.log(err);
              });
            return;
          }

          if (res.data.code == 201) {
            reject(res);
            return;
          }
          if (res.data.code == 202) {
            reject(res.data);
            return;
          }
          reject(res.data);
          Message(res.data.msg);
        }

        // resolve(res)
      })
      .catch((res) => {
        if (loadingInstance) {
          loadingInstance.close();
        }

        reject(res);
      });
  });
}

export default {
  host,
  imgHost,
};
