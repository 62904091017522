import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { getUserInfo, cardtType, recoveryDiscount } from "@/api/api.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showBtn: true, // 键盘上移隐藏
    token: "",
    hasLogin: false, //是否登录,
    userInfo: "", //用户信息
    prePage: "", //上一页面路径
    historyList: [], //浏览记录
    typeOue: "",
    detailsData: "",
    notice: "",
    indexHome: "/index.html",
    // iosHome:"/index.html?hasNode=1",
    iosHome: "/index.html?hasNode=1",
    webHome: "/web/home",
    website: "",
    url: "",
    app: false, //打包安卓 true, 打包h5,false
    iosAnd: true, // 打包ios，和 安卓为true ，打包正常h5，为 false
    h5_Ios: "h5", //如果打包成 正常h5，为 h5, 打包为ios，为 ios
    config: {},
    safari: false, //判断首页头部样式的
    appVersion: "1.0.3",
  },
  getters: {},
  mutations: {
    showBtnCount(state, value) {
      state.showBtn = value;
    },
    tokenCount(state, value) {
      state.token = value;
    },
    UpH5Ios(state, data) {
      state.h5_Ios = data;
    },
    UpVersion(state, data) {
      state.appVersion = data;
    },
    upConfig(state, info) {
      state.config = info;
    },
    /*登录*/
    login(state, token) {
      state.hasLogin = true;
      state.token = token || null;
      window.sessionStorage.setItem("token", token);
    },
    isSafari(state, data) {
      if (state.safari) return;
      state.safari = data;
    },
    setHistoryList(state, data) {
      state.historyList = data || [];
      window.setStorage({
        //将用户信息保存在本地
        key: "historyList",
        data: data,
      });
    },
    upDataUrl(state, url) {
      state.url = url;
    },
    /*登出*/
    logout(state) {
      state.hasLogin = false;
      state.token = "";
      state.userInfo = "";
      window.sessionStorage.removeItem("token");
      localStorage.removeItem("vuex");
    },
    detailsData(state, item) {
      state.detailsData = item;
    },
    /*更新用户信息*/
    setUserInfo(state, item) {
      state.userInfo = item;
    },

    /*更新上一页面路径*/
    updatePrePage(state, item) {
      state.prePage = item;
    },
    // 获取分类数据
    getTypeOue(state, item) {
      state.typeOue = item;
    },
    setNotice(state, item) {
      state.notice = item;
    },
  },
  actions: {
    getInfo(ctx) {
      if (ctx.state.userInfo == "") {
        return new Promise((resD, ret) => {
          getUserInfo().then((res) => {
            ctx.commit("setUserInfo", res.data.data);
            resD(res.data.data);
          });
        });
      } else {
        return ctx.state.userInfo;
      }
    },
    getNoticeD(ctx) {
      if (ctx.state.notice == "") {
        return new Promise((resD, ret) => {
          recoveryDiscount().then((res) => {
            ctx.commit("setNotice", res.data.data);
            resD(res.data.data);
          });
        });
      } else {
        return ctx.state.notice;
      }
    },
    updateInfo(ctx) {
      return new Promise((resD, ret) => {
        getUserInfo().then((res) => {
          ctx.commit("setUserInfo", res.data.data);
          resD(res.data.data);
        });
      });
    },
    getUser(ctx) {
      getUserInfo().then((res) => {
        if (res.data.status != 1) {
          return;
        }
        if (res.data.result.head_pic.indexOf("public") == 1) {
          res.data.result.head_pic = config.host + res.data.result.head_pic;
        }
        ctx.commit("setUserInfo", res.data.result);
      });
    },
    getType(ctx) {
      cardtType().then((res) => {
        console.log(res);
        ctx.commit("getTypeOue", res.data.dataa);
      });
    },
    /*设置上一页面路径*/
    setPrePage(ctx, item) {
      ctx.commit("updatePrePage", item);
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(data) {
        return {
          token: data.token,
        };
      },
    }),
  ],
});
