<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>

export default {
  data () {
    return {
      docmHeight: document.documentElement.clientHeight, // 默认屏幕高度
      showHeight: document.documentElement.clientHeight, // 实时屏幕高度
      hideshow: true // 控制按钮盒子显示隐藏

    }
  },
  created () {
    const urlSuffix = location.hash.split('=')
    if (urlSuffix[0].indexOf('token') > 0) {
      this.$store.commit('tokenCount', urlSuffix[1])
    }

    window.onload = () => {
      document.addEventListener('touchstart', (event) => {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
      })
    }
  },
  mounted () {
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight
      })()
    }
  },
  watch: {
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.$store.commit('showBtnCount', false)
        this.hideshow = false
      } else {
        this.$store.commit('showBtnCount', true)
        this.hideshow = true
      }
    }

  }
}
</script>
<style lang="less" scoped>
</style>
