<template>
  <van-tabbar v-model="active"
              placeholder
              v-show="$store.state.showBtn"
              active-color="#069DE6"
              route
              safe-area-inset-bottom>
    <van-tabbar-item icon="wap-home-o"
                     replace
                     name='index'
                     to="/index">首页</van-tabbar-item>
    <van-tabbar-item icon="todo-list-o"
                     replace
                     name='orderInquiry'
                     to="/orderInquiry">回收记录</van-tabbar-item>
    <van-tabbar-item icon="balance-list-o"
                     replace
                     name='withdrawal'
                     to="/withdrawal">打款记录</van-tabbar-item>
    <van-tabbar-item icon="service"
                     replace
                     name='service'
                     to="/service">客服</van-tabbar-item>
    <!-- replace to="/search -->
  </van-tabbar>
</template>

<script>
export default {
  data () {
    return {
      active: 0
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-tabbar {
  position: absolute;
  z-index: 99999;
}
</style>
