<template>
  <div>

    <router-view />

    <footer>
      <TabBar />
    </footer>
  </div>
</template>

<script>
import TabBar from './components/tabBar.vue'
export default {
  components: {
    TabBar
  }
}
</script>

<style lang="less" scoped>
main {
}
</style>
