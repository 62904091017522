import Vue from "vue";
import VueRouter from "vue-router";
import index from "@/layout/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: index,
    redirect: "/index",
    children: [
      // 首页
      {
        path: "/index",
        name: "index",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/index/index.vue"),
      },
      // 订单查询
      {
        path: "/orderInquiry",
        name: "orderInquiry",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/orderInquiry"),
      },
      // 提现记录
      {
        path: "/withdrawal",
        name: "withdrawal",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/withdrawalRecord"),
      },
      // 客服
      {
        path: "/service",
        name: "service",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/service"),
      },
    ],
  },
  {
    path: "/index/exchange",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index/exchange.vue"),
  },
  {
    path: "/index/consignment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index/consignment.vue"),
  },
  // 实名认证
  {
    path: "/realname",
    name: "realname",
    component: () => import(/* webpackChunkName: "about" */ "@/views/realname"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
