import { request } from "./config.js";

//产品，首页部分
// 获取首页banner

export async function getBanner(params) {
  return request("/suggest", "post", params);
}

export async function testget(params) {
  return request("/index/testget", "get", params);
}

// 登录
export async function login(params) {
  return request("/login", "post", params);
}

// 注册
export async function register(params) {
  return request("/register", "post", params);
}

// 获取验证码
export async function code(params) {
  return request("/sms", "post", params);
}

// 退出登录
export async function loginOut(params) {
  return request("/logout", "get", params);
}

// 获取用户信息
export async function getUserInfo(params) {
  return request("/member/info", "get", params);
}

// 获取订单信息，经营状况
export async function getUserOrder(params) {
  return request("/member/order", "get", params);
}

// 修改密码
export async function modifyPwd(params) {
  return request("/member/change", "post", params);
}
// 找回密码
export async function findpwd(params) {
  return request("/findpwd", "post", params);
}

// 获取卡种分类
export async function cardtType(params) {
  return request("/card/type", "get", params);
}

// 获取卡种列表
export async function cardtTypeList(params) {
  return request("/card/list", "get", params);
}

// 获取卡种面值
export async function cardtTypeValue(params) {
  return request("/card/value", "get", params);
}

// 卡密明细
export async function CarMyDetailed(params) {
  return request("/orders/mycards", "post", params);
}
//  卖卡记录
export async function sellRecord(params) {
  return request("/orders/mylist", "post", params);
}

//  订单详情
export async function orderList(params) {
  return request("/orders/info", "post", params);
}

//  订单详情
export async function recoveryDiscount(params) {
  return request("/card/discount", "get", params);
}

//  资金明细
export async function fundRecord(params) {
  return request("/member/accountlog", "post", params);
}

//  个人认证，身份证提交
export async function setCardID(params) {
  return request("/member/identity", "post", params);
}

//  个人认证，身份证提交
export async function cartSubmit(params) {
  return request("/orders/sell", "post", params);
}

//  获取银行名字
export async function getBankName(params) {
  return request("/bank/card", "post", params);
}
//  增加提现账号
export async function addBank(params) {
  return request("/bank/create", "post", params);
}

//  查看绑定的提现账号
export async function seeBankList(params) {
  return request("/bank/list", "get", params);
}

// 删除已绑定账号
export async function remoBank(params) {
  return request("/bank/del", "post", params);
}

// 申请提现
export async function withdrawMoney(params) {
  return request("/withdraw/cash", "post", params);
}

// 提现记录
export async function withdrawList(params) {
  return request("/withdraw/list", "post", params);
}

// 提现记录
export async function loginlogs(params) {
  return request("/loginlogs", "post", params);
}

// 修改折扣
export async function discountUpdate(params) {
  return request("/orders/update", "post", params);
}

// 获取每日额度
export async function quota(params) {
  return request("/member/quota", "get", params);
}

// 高级认证上传身份证
export async function senior(params, headers, isFalse) {
  return request("/member/senior", "post", params, headers, isFalse);
}

// 企业认证
export async function company(params, headers, isFalse) {
  return request("/member/company", "post", params, headers, isFalse);
}

// 企业认证查询签名地址
export async function companyQuery(params) {
  return request("/company/url", "get", params);
}

// 获取地区信息
export async function area(params) {
  return request("/area", "post", params);
}

// 获取开户行列表
export async function branch(params) {
  return request("/branch", "post", params);
}

// 获取折扣列表
export async function baiDiscount(params) {
  return request("/card/baidu", "get", params);
}

// 首页信息
export async function home(params) {
  return request("/home", "get", params);
}

// 文章资讯列表
export async function news(params) {
  return request("/news", "get", params);
}

// 获取公告
export async function getNotice(params) {
  return request("/notices/list", "get", params);
}

// 获取文章详情
export async function getDetail(params) {
  return request("/detail", "get", params);
}

// 获取文章 最新发布，点击排行
export async function newsRanking(params) {
  return request("/others", "get", params);
}

// 基础配置
export async function config(params) {
  return request("/config", "get", params);
}

// 银行配置
export async function banklist(params) {
  return request("/banklist", "get", params);
}

// 获取搜索接口
export async function search(params) {
  return request("/card/search", "post", params);
}

// 判断登录状态
export async function checkLogin(params) {
  return request("/checkLogin", "get", params);
}

// 添加账户判断
export async function paytype(params) {
  return request("/member/getpaytype", "post", params);
}

// 判断有没有增加微信提现账号
export async function bindwithdraw(params) {
  return request("/member/bindwithdraw", "post", params);
}

// 生成二维码
export async function wechatimg(params) {
  return request("/member/wechatimg", "post", params);
}
