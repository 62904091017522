import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "vant/lib/index.css";
import "./assets/css/reset.css"; // 清除默认样式
import "./assets/css/index.less"; // 公共样式

// import '@/utils/rem'

import {
  NavBar,
  Button,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Field,
  ActionSheet,
  DatetimePicker,
  Checkbox,
  CheckboxGroup,
  Col,
  Row,
  Pagination,
  Dialog,
  Toast,
  Icon,
  Loading,
  Empty,
  NoticeBar,
  NumberKeyboard,
} from "vant";
Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Pagination);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Empty);
Vue.use(NoticeBar);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(NumberKeyboard);
Vue.prototype.Dialog = Dialog;
Vue.prototype.$Toast = Toast;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
